import { router } from "@inertiajs/react";
import { Launch, PictureAsPdfOutlined } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { ROUTES } from "app/frontend/constants/routes";
import { useModal } from "app/frontend/hooks/useModal";
import { Fragment, lazy, PropsWithChildren, Suspense, useCallback, useMemo } from "react";

const DACLMIScreener = lazy(() => import("app/frontend/components/modals/dac_lmi_screener/DACLMIScreenerModal"));

const LinkButton: React.FC<
    {
        button?: ButtonProps;
        href: string;
        hideIcons?: boolean;
    } & PropsWithChildren
> = ({ children, href, button, hideIcons }) => {
    const { open, setOpen, setClosed } = useModal();

    const isDACScreenerModal = useMemo(() => href === ROUTES.DacScreenerModal, [href]);
    const isExternalLink = useMemo(() => !href.startsWith("/"), [href]);
    const isPDF = useMemo(() => href.endsWith(".pdf"), [href]);

    const handleNavigate = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();

            router.visit(href);
        },
        [href],
    );

    const handleOpenDACScreener = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();

            setOpen();
        },
        [setOpen],
    );

    // https://www.a11yproject.com/checklist/#identify-links-that-open-in-a-new-tab-or-window
    // https://www.w3.org/WAI/WCAG22/Techniques/general/G201
    // https://www.w3.org/WAI/WCAG22/working-examples/css-new-window-warn/
    const icons = useMemo(() => {
        const _icons = [];

        if (!hideIcons && typeof children === "string") {
            if (isPDF) {
                _icons.push(
                    <Fragment key={`pdf-${href}`}>
                        &nbsp;
                        <PictureAsPdfOutlined
                            className="pdf-icon"
                            // aria-valuetext="PDF"
                            // aria-hidden={true}
                            titleAccess="PDF"
                            // title="PDF"
                            sx={{
                                fontSize: "1.5em",
                                fontWeight: "bolder",
                                verticalAlign: "middle",
                            }}
                        >
                            <use href="#pdf-icon" />
                            <figcaption>PDF</figcaption>
                        </PictureAsPdfOutlined>
                    </Fragment>,
                );
            }
            if (isExternalLink) {
                _icons.push(
                    <Fragment key={`external-${href}`}>
                        &nbsp;
                        <Launch
                            className="new-window-icon"
                            // aria-valuetext="External Link"
                            // aria-hidden={true}
                            titleAccess="External Link - Opens New Tab/Window"
                            // title="External Link - Opens New Tab/Window"
                            sx={{
                                fontSize: "1.5em",
                                fontWeight: "bolder",
                                verticalAlign: "middle",
                            }}
                        >
                            <use href="#new-window-icon" />
                            <figcaption>External Link - Opens New Tab/Window</figcaption>
                        </Launch>
                    </Fragment>,
                );
            }
        }
        return _icons;
    }, [isExternalLink, isPDF, href, children, hideIcons]);

    return (
        <>
            {/* @ts-expect-error - Property 'target' does not exist on type 'IntrinsicAttributes & ButtonOwnProps & Omit<ButtonBaseOwnProps, "classes"> & CommonProps & Omit<...>'. */}
            <Button // NOSONAR
                {...button}
                sx={{
                    padding: 0,
                    ...button?.sx,
                }}
                style={{ fontWeight: "bolder" }}
                size={button?.size}
                onClick={isDACScreenerModal ? handleOpenDACScreener : isExternalLink ? undefined : handleNavigate}
                role="link"
                href={isDACScreenerModal ? "#" : href}
                rel="noopener noreferrer"
                target={isExternalLink ? "_blank" : "_self"}
            >
                {children}
                {icons}
            </Button>
            {open && (
                <Suspense fallback={null}>
                    <DACLMIScreener open={open} handleClose={setClosed} />
                </Suspense>
            )}
        </>
    );
};

export default LinkButton;
